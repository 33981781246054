
//
import {dom} from './libs/dom/dom';
import {events} from './libs/events/events';
import EventManager from './libs/events/EventManager';
import {ElementData} from './libs/dom/ElementData';

import './libs/eq/eq';

const forEach = function (array, callback, scope) {
	for (let i = 0; i < array.length; i++) {
		callback.call(scope, i, array[i]); // passes back stuff we need
	}
};

let counter = 0;
const getId = function (el)
{
	if(!el.id)
	{
		el.id					= `dynid-${++counter}`;
	}

	return el.id;
};

EventManager.debug				= false;

// when the page is ready, call all the events to start the javascript
events.ready(() => {
	// add the user agent as a data attribute to the html tag, this can be used for css to make browser
	// specific tweaks/changes
	document.documentElement.setAttribute('data-useragent', navigator.userAgent);

	// TODO: switch to classList.replace
	document.documentElement.classList.remove('no-js');
	document.documentElement.classList.add('js');

	const hamburger				= document.getElementById('menu-toggle');
	const navigation			= document.getElementById('navigation');

	if(hamburger)
	{
		let open				= false;

		events.on(hamburger, 'click', function(ev)
		{
			events.cancel(ev);

			open				= !open;

			if(open)
			{
				dom.addClass(hamburger, 'open');
				dom.addClass(navigation, 'open');
			}
			else
			{
				dom.removeClass(hamburger, 'open');
				dom.removeClass(navigation, 'open');
			}
		});
	}


	const inputs				= document.querySelectorAll('.form__field input, .form__field textarea');

	for(var i = 0; i < inputs.length; i++)
	{
		const input				= inputs[i];

		if(input.value) {
			dom.addClass(dom.getClosest(input, '.form__field'), 'push');
		}

		events.on(input, 'focus', function(ev) {
			dom.addClass(dom.getClosest(this, '.form__field'), 'push');
		});

		events.on(input, 'input', function(ev) {
			const value			= this.value;

			if(!value && (this !== document.activeElement)) {
				dom.removeClass(dom.getClosest(this, '.form__field'), 'push');
			}
			else
			{
				dom.addClass(dom.getClosest(this, '.form__field'), 'push');
			}
		});

		events.on(input, 'blur', function(ev) {
			const value			= this.value;

			if(!value) {
				dom.removeClass(dom.getClosest(this, '.form__field'), 'push');
			}
		});
	}

	//
	EventManager.do_action('page_ready');

	// initialize all the content modules and set everything up
	EventManager.do_action('init_content');

	// if there is a hash, trigger the hash change on the page onload
	if(window.location.hash)
	{
		// there may be noting that is called from this hook.. however, in this case there is, we
		// watch for this hook being triggered in toggle.js
		EventManager.do_action('hash_change', window.location.hash);
	}
});

// add a hashchange watch, for changes in the hash and trigger the hash change action
events.on(window, 'hashchange', (ev) => {
	EventManager.do_action('hash_change', window.location.hash);
});
