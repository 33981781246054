
/**
 * EventManager Module
 * @module events
 * @global
 */
const EventManager =
{
	get debug ()
	{
		return window._evm;
	},

	/**
	 * Toggle debugging on and off
	 * @param  {Boolean} bool			Turn on/off debugging/logging of events that take place
	 * @retutn null
	 */
	set debug (val)
	{
		window._evm				= val;
	},

	/**
	 * Private method to check if a hook has been added or not
	 * @param  {String}  type 			type of event (action or filter)
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Boolean}	  			hook exists outcome
	 */
	_has (type, hook)
	{
		type		   			= `_${type}`;

		if(!window[type])
		{
			return false;
		}

		if(!window[type][hook])
		{
			return false;
		}

		return true;
	},

	/**
	 * Private function to hold all the hooks
	 * @param  {String}  type 			type of event (action or filter)
	 * @param  {String}  hook 			name of the hook to check for
	 * @param  {Object}  func			function/callback to add
	 * @param  {Integer} priority		priority to the hook to be called
	 * @param  {Integer} accepted_args	the number of arguments to get applied
	 */
	_create (type, hook, func, priority = 10, accepted_args = 1)
	{
		// make sure that the namespace is marked as private/not easily accessible
		type		   						= `_${  type}`;

		// make sure that window variables exist
		if(!window[type])
		{
			window[type]					= {};
		}

		if(!window[type][hook])
		{
			window[type][hook]	 			= [];
		}

		if(!window[type][hook][priority])
		{
			window[type][hook][priority]	= [];
		}

		// add the hook
		window[type][hook][priority].push({func, args:accepted_args});
	},

	/**
	 * Private function to trigget the hook and callbacks
	 * @param  {String}  type 			type of event (action or filter)
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Object}					Return value for the hook
	 */
	_do (type, hook)
	{
		let value		= arguments[2];
		const _type		= `_${  type}`;

		if(window[_type])
		{
			const list	= window[_type][hook];

			// if debugging is turned on log the details
			if(window._evm === true)
			{
				console.log(`-> Running ${type}`, hook);
			}

			if(list)
			{
				for(const i in list)
				{
					// get the list of functions
					const functions = list[i];

					// loop through all the functions
					for(const j in functions)
					{
						// function callback
						let func			= functions[j].func;
						let target			= null;

						// if the function is an array, we're calling a method on an object
						if(func instanceof Array)
						{
							target			= func[0];
							func			= func[1];

							if(typeof(func) === 'string')
							{
								func		= target[func];
							}
						}

						// if debugging it turned on, log it
						if(window._evm === true)
						{
							console.log('   [func]', func.name);
						}

						const args			= Array.prototype.slice.call(arguments, 2, functions[j].args + 2);
							args[0]			= value;

						// update the return value
						value				= func.apply(target, args);
					}
				}
			}
		}

		// return the value returned from any of the callbacks
		return value;
	},

	/**
	 * Add an action
	 * @param  {String}  hook 			name of the hook to check for
	 * @param  {Object}  func			function/callback to add
	 * @param  {Integer} priority		priority to the hook to be called
	 * @param  {Integer} accepted_args	the number of arguments to get applied
	 */
	add_action (hook, func, priority = 10, accepted_args = 1)
	{
		this._create('action', hook, func, priority, accepted_args);
	},

	/**
	 * Execute an action
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Object}					return value for the action
	 */
	do_action (hook)
	{
		const args				= Array.prototype.slice.call(arguments);

		// add action to the start of the array as the array is going to be used as params in the _do function
		args.unshift('action');

		return this._do.apply(this, Array.prototype.slice.call(args));
	},

	/**
	 * Check that a action has been defined
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Bool}					true/false of the existance of the action hook
	 */
	has_action (hook)
	{
		return this._has('action', hook);
	},

	/**
	 * Add a filter
	 * @param  {String}  hook 			name of the hook to check for
	 * @param  {Object}  func			function/callback to add
	 * @param  {Integer} priority		priority to the hook to be called
	 * @param  {Integer} accepted_args	the number of arguments to get applied
	 */
	add_filter (hook, func, priority = 10, accepted_args = 1)
	{
		this._create('filter', hook, func, priority, accepted_args);
	},

	/**
	 * Execute a filter
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Object}					return value for the filter
	 */
	apply_filters (hook)
	{
		const args				= Array.prototype.slice.call(arguments);

		// add filter to the start of the array as the array is going to be used as params in the _do function
		args.unshift('filter');

		return this._do.apply(this, Array.prototype.slice.call(args));
	},

	/**
	 * Check that a filter has been defined
	 * @param  {String}  hook 			name of the hook to check for
	 * @return {Bool}					true/false of the existance of the filter hook
	 */
	has_filter (hook)
	{
		return this._has('filter', hook);
	}
};

export default EventManager;
