
const closest = (function ()
{
	const el		= HTMLElement.prototype;
	const matches	= el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

	return function closest (el, selector)
	{
		try
		{
			return matches.call(el, selector) ? el : closest(el.parentElement, selector);
		}
		catch(e)
		{
			return false;
		}
	};
})();

const dom =
{
	getPageScroll ()
	{
		if (window.pageYOffset)
		{
			return window.pageYOffset;
		}

		if (document.documentElement && document.documentElement.scrollTop)
		{
			return document.documentElement.scrollTop;
		}

		if (document.body)
		{
			return document.body.scrollTop;
		}

		return false;
	},

	position (elm)
	{
		const box					= elm.getBoundingClientRect();

		const body				= document.body;
		const docEl				= document.documentElement;

		const scrollTop			= window.pageYOffset || docEl.scrollTop || body.scrollTop;
		const scrollLeft			= window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

		const clientTop			= docEl.clientTop || body.clientTop || 0;
		const clientLeft			= docEl.clientLeft || body.clientLeft || 0;

		const top					= box.top +  scrollTop - clientTop;
		const left				= box.left + scrollLeft - clientLeft;

		return {
			top		: Math.round(top),
			left	: Math.round(left)
		};
	},

	height (elm)
	{
		return elm.clientHeight;
	},

	/**
	 * Remove an element from the DOM
	 * @param  {Element} elm [description]
	 */
	remove (elm)
	{
		elm.parentNode.removeChild(elm);
	},

	/**
	 * [after description]
	 * @param  {Element} elm		[description]
	 * @param  {Element} htmlString [description]
	 */
	after (elm, htmlString)
	{
		elm.insertAdjacentHTML('afterend', htmlString);
	},

	/**
	 * [insertAfter description]
	 * @param  {Element} elm	 [description]
	 * @param  {Element} newNode [description]
	 */
	insertAfter (elm, newNode)
	{
		elm.parentNode.insertBefore(newNode, elm.nextSibling);
	},

	/**
	 * [append description]
	 * @param  {Element} elm	 [description]
	 * @param  {Element} newNode [description]
	 */
	append (elm, newNode)
	{
		elm.appendChild(newNode);
	},

	/**
	 * [prepend description]
	 * @param  {Element} elm	 [description]
	 * @param  {Element} newNode [description]
	 */
	prepend (elm, newNode)
	{
		elm.insertBefore(newNode, elm.firstChild);
	},

	/**
	 * [before description]
	 * @param  {Element} elm		[description]
	 * @param  {Element} htmlString [description]
	 */
	before (elm, htmlString)
	{
		elm.insertAdjacentHTML('beforebegin', htmlString);
	},

	/**
	 * [clone description]
	 * @param  {Element} elm [description]
	 * @return {Element}	 [description]
	 */
	clone (elm)
	{
		return elm.cloneNode(true);
	},

	/**
	 * [next description]
	 * @param  {Element}   elm [description]
	 * @return {Function}	 [description]
	 */
	next (elm)
	{
		return elm.nextElementSibling;
	},

	/**
	 * [find description]
	 * @param  {Element} elm	  [description]
	 * @param  {Element} selector [description]
	 * @return {Element}		  [description]
	 */
	find (elm, selector)
	{
		return elm.querySelectorAll(selector);
	},

	/**
	 * [prev description]
	 * @param  {Element} elm [description]
	 * @return {Element}	 [description]
	 */
	prev (elm)
	{
		return elm.previousElementSibling;
	},

	/**
	 * [selectAll description]
	 * @param  {Element} query [description]
	 * @return {Element}	   [description]
	 */
	selectAll (query, base = document)
	{
		return base.querySelectorAll(query);
	},

	/**
	 * [scrollPosition description]
	 * @return {Element} [description]
	 */
	scrollPosition ()
	{
		const doc		= document.documentElement;

		return {
			top		: (window.pageYOffset || doc.scrollTop ) - (doc.clientTop  || 0),
			left	: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
		};
	},

	/**
	 * [create description]
	 * @param  {Element} str	  [description]
	 * @param  {Element} location [description]
	 * @return {Element}		  [description]
	 */
	create (str, location)
	{
		const temp			= document.createElement('div');
		temp.innerHTML		= str;

		let children;

		if (!location)
		{
			children		= temp;
		}
		else
		{
			children		= temp.children;

			arr.each(location, (str, i) => {
				children	= (i == location.length - 1) ? children[str] : children[str].children;
			});
		}

		return children;
	},

	containsClass : (function ()
	{
		if(document.documentElement.classList)
		{
			/**
			 * [description]
			 * @param  {Element} elm	   [description]
			 * @param  {Element} className [description]
			 */
			return function (elm, className)
			{
				if(!elm)
				{
					return false;
				}

				return elm.classList.contains(className);
			}
		}

		/**
		 * [description]
		 * @param  {Element} elm	   [description]
		 * @param  {Element} className [description]
		 */
		return function (elm, className)
		{
			if (!elm || !elm.className)
			{
				return false;
			}

			const re		= new RegExp(`(^|\\s)${  className  }(\\s|$)`);

			return elm.className.match(re);
		}
	})(),

	addClass : (function ()
	{
		if(document.documentElement.classList)
		{
			/**
			 * [description]
			 * @param  {Element} elm	   [description]
			 * @param  {Element} className [description]
			 */
			return function (elm, className)
			{
				if(!elm)
				{
					return false;
				}

				if (className.indexOf(' ') > 0)
				{
					const names	= className.split(' ');

					for (let i = 0, il = names.length; i < il; i++)
					{
						elm.classList.add(names[i]);
					}
				}
				else
				{
					elm.classList.add(className);
				}
			}
		}

		/**
		 * [description]
		 * @param  {Element} elm	   [description]
		 * @param  {Element} className [description]
		 */
		return function (elm, className)
		{
			if (!elm)
			{
				return false;
			}

			if (! this.containsClass(elm, className))
			{
				elm.className	+= (elm.className ? ' ' : '') + className;
			}
		}
	})(),

	removeClass : (function ()
	{
		if(document.documentElement.classList)
		{
			/**
			 * [description]
			 * @param  {Element} elm	   [description]
			 * @param  {Element} className [description]
			 */
			return function (elm, className)
			{
				if(!elm)
				{
					return false;
				}

				elm.classList.remove(className);
			}
		}

		/**
		 * [description]
		 * @param  {Element} elm	   [description]
		 * @param  {Element} className [description]
		 */
		return function (elm, className)
		{
			if (!elm || !elm.className)
			{
				return false;
			}

			const regexp		= new RegExp(`(^|\\s)${  className  }(\\s|$)`, 'g');

			elm.className	= elm.className.replace(regexp, '$2');
		}
	})(),

	toggleClass : (function ()
	{
		if(document.documentElement.classList)
		{
			/**
			 * [description]
			 * @param  {Element} elm	   [description]
			 * @param  {Element} className [description]
			 */
			return function (elm, className)
			{
				if(!elm)
				{
					return false;
				}

				return elm.classList.toggle(className);
			}
		}

		/**
		 * [description]
		 * @param  {Element} elm	   [description]
		 * @param  {Element} className [description]
		 */
		return function (elm, className)
		{
			if(!elm)
			{
				return false;
			}

			if (this.containsClass(elm, className))
			{
				this.removeClass(elm, className);
				return false;
			}
			else
			{
				this.addClass(elm, className);
				return true;
			}
		}
	})(),

	/**
	 * Get the closest matching element up the DOM tree.
	 * @private
	 * @param  {Element} elem	 Starting element
	 * @param  {String}  selector Selector to match against (class, ID, data attribute, or tag)
	 * @return {Boolean|Element}  Returns null if not match found
	 */
	getClosest (elm, selector)
	{
		// Variables
		const firstChar			= selector.charAt(0);
		const supports			= 'classList' in document.documentElement;

		let attribute, value;

		// If selector is a data attribute, split attribute from value
		if (firstChar === '[')
		{
			selector			= selector.substr(1, selector.length - 2);
			attribute			= selector.split('=');

			if (attribute.length > 1)
			{
				value			= true;
				attribute[1]	= attribute[1].replace(/"/g, '').replace(/'/g, '');
			}
		}

		// Get closest match
		for (; elm && elm !== document; elm = elm.parentNode) {

			// If selector is a class
			if (firstChar === '.')
			{
				if (supports)
				{
					if (elm.classList.contains(selector.substr(1)))
					{
						return elm;
					}
				}
				else
				{
					if (new RegExp(`(^|\\s)${  selector.substr(1)  }(\\s|$)`).test(elm.className))
					{
						return elm;
					}
				}
			}

			// If selector is an ID
			if (firstChar === '#')
			{
				if (elm.id === selector.substr(1))
				{
					return elm;
				}
			}

			// If selector is a data attribute
			if (firstChar === '[')
			{
				if (elm.hasAttribute(attribute[0]))
				{
					if (value)
					{
						if (elm.getAttribute(attribute[0]) === attribute[1])
						{
							return elm;
						}
					}
					else
					{
						return elm;
					}
				}
			}

			// If selector is a tag
			if (elm.tagName.toLowerCase() === selector)
			{
				return elm;
			}
		}

		return null;
	},

	closest
};

export {dom};
